import { AuthenticatedUser } from "@/lib/auth.server";
import { useMatches } from "react-router";
import { useEffect, useMemo } from "react";
import { ActionFunction } from "@remix-run/cloudflare";
import { useActionData, useNavigate } from "@remix-run/react";

const options = {
  "formal": "Your writing style is formal and your words are precise. You favor short paragraphs over long ones (maximum 3 sentences per paragraph). Keep it short and simple.",
  "engaging": "Your writing style is engaging and favors very short paragraphs over long ones (maximum 3 sentences per paragraph, 2 are better). Keep it short and simple. Use 6th grade language! Never use jargon.",
  "none": ""
};
export const getToneOfVoice = (key: keyof typeof options) => {
  return options[key];
};

type OptionsKey = keyof typeof options;

export const isToneOfVoiceKey = (key: string): key is OptionsKey => {
  return Object.keys(options).includes(key) || key === "custom";
};


export function useMatchesData(
  id: string
): Record<string, any> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  return route?.data as any;
}

export const useStreamedSignal = (action: ActionFunction, navToFunc: (x: string|undefined)=>string)=>{
  const data = useActionData<typeof action>();

  const nav = useNavigate();
  useEffect(() => {
    if (data) {
      if (!(data as string).includes("n")) {

        let actualData = "";
        if((data as string).length !== 0){
          actualData = (data as string)?.split(";")?.[1]??"";
        }
        const navTo = navToFunc(actualData);

        nav(navTo);
      }
    }
  }, [data]);

  return data;
}

export function useUser(): AuthenticatedUser | undefined {
  const data = useMatchesData("root");
  if (!data) {
    return undefined;
  }
  return data.user;
}

export const maxTrialCredits = 15;

export const formatGscUrl = (url: string) => {
  return url.replace("sc-domain:", "").replace("https://", "").replace("http://", "").replace("/", "");
};

